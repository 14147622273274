@import url(https://fonts.googleapis.com/css2?family=Kameron:wght@400;700&display=swap);

:root {
  --max-game-width: 500px;
  --keyboard-height: 200px;
  --header-height: 52px;
  --color-tone-1: #ffffff;
  --color-tone-2: #818384;
  --color-tone-3: #565758;
  --color-tone-4: #3a3a3c;
  --color-tone-5: #272729;
  --color-tone-6: #1a1a1b;
  --color-tone-7: #121213;
  --color-nav-hover: #2f2f31;
  --green: #6aaa64;
  --darkened-green: #538d4e;
  --yellow: #c9b458;
  --darkened-yellow: #b59f3b;
  --tile-present: var(--darkened-yellow);
  --tile-correct: var(--darkened-green);
  --tile-absent: var(--color-tone-4);
  --key-bg: var(--color-tone-2);
  --key-bg-absent: var(--color-tone-4);
  --key-bg-correct: var(--darkened-green);
  --key-bg-present: var(--darkened-yellow);
  --key-text-color: var(--color-tone-1);
  --tile-flip-speed: 0.33s;
}

#root {
  height: 100%;
  display: flex;
  flex-direction: column;
  font-family: helvetica;
}

/* prevent side to side scrolling on mobile */
html, body {
  overflow-x: hidden;
}
body {
  position: relative;
}

html,body, .container {
    height: 100%;
}

body {
  background: var(--color-tone-7);
  margin: 0 auto;
}

.game-container {
    height: 100%;
}

.game {
  display: flex;
  flex-direction: column;
  max-width: var(--max-game-width);
  width: 100%;
  margin: 0 auto;
  height: calc(100% - var(--header-height));
}

.header {
  text-align: center;
  width: 100%;
  border-bottom: 1px solid var(--color-tone-3);
  color: #fff;
  font-family: 'Kameron', 'serif';
  padding: 2px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  flex-wrap: nowrap;
}

/* * {
  outline: 1px solid #ccc;
  background: rgba(0, 50, 0, .1);
} */

.header .title h1 {
  margin: 0;
  padding: 0;
  font-size: 2.3em;
  font-weight: 700;
}

.header .title p {
  margin: 0;
  padding: 0;
  font-size: .8em;
  font-weight: 400;
}

.header .menu-left,
.header .menu-right {
    width: 24px;
    margin: 10px;
}

.header .help-icon {
  display: flex;
  margin: 0;
  padding: 0;
  align-items: center;
  cursor:pointer;
  justify-content: flex-start;
}

.board-container {
  display: flex;
  align-items: center;
  flex-grow: 1;
  justify-content: center;
  overflow-x: hidden;
  overflow-y: hidden;
}
.board-game {
  width: 350px;
  height: 420px;
  display: grid;
  grid-template-rows: repeat(6, 1fr);
  grid-gap: 5px;
  border: 1px #00f;
  padding: 20px;
}

.game-row {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-gap: 5px;
}

.game-row.rejected {
  animation-name: rejected-shake;
  animation-duration: 0.25s;
  animation-timing-function: ease-in;
}

.tile {
  border: 2px solid var(--color-tone-4);
  width: 100%;
  box-sizing: border-box;
  display: inline-flex;
  justify-content: center;
  color: #fff;
  font-weight: bold;
  font-size: 2rem;
  align-items: center;
}

.tile.tbd{
  border-color: var(--color-tone-3);
  animation-name: tile-tbd;
  animation-duration: 0.1s;
}

.tile.present,
.tile.absent,
.tile.correct {
  animation-name: flip-hor, change-background, happy-tile;
  animation-duration: var(--tile-flip-speed), calc(var(--tile-flip-speed) / 2), calc(var(--display-happy-tile) * calc(var(--tile-flip-speed) / 2));
  animation-timing-function: ease-out, step-end, ease-out;
  animation-fill-mode: forwards;
  --delay:  calc(var(--index) * var(--tile-flip-speed));
  --happy-tile-delay: calc(calc(var(--delay) / 4) + calc(var(--wordLength) * var(--tile-flip-speed)) );
  animation-delay:var(--delay), var(--delay), var(--happy-tile-delay);
}

.keyboard {
  text-align: center;
  height: var(--keyboard-height);
  display: block;
}

button.key {
  background-color: var(--color-tone-2);
  color: #fff;
  border-radius: 2px;
  border: none;
  padding: 10px 10px;
  font-weight: bold;
  border: 0;
  min-width: 35px;
  margin: 0 5px 5px 0;
  height: 58px;
  border-radius: 4px;
  cursor: pointer;
  user-select: none;
  background-color: var(--key-bg);
  color: var(--key-text-color);
  justify-content: center;
  align-items: center;
  text-transform: uppercase;
  -webkit-tap-highlight-color: rgba(0,0,0,0.3);
  transition: all step-end;
  transition-delay: calc(var(--wordLength) * var(--tile-flip-speed))
}

button.key.absent {
  background-color: var(--key-bg-absent);
}

button.key.present { 
  background-color: var(--key-bg-present);
}

button.key.correct {
  background-color: var(--key-bg-correct);
}

.alertContainer {
  position: absolute;
  top: 10%;
  left: 50%;
  transform: translate(-50%, 0);
  pointer-events: none;
  width: fit-content;
  z-index: 1000;
}

.alert {
  background-color: var(--color-tone-1);
  opacity: 0.0;
  margin: 5px;
  border-radius: 5px;
  padding: 15px;
  font-weight:bold;
  text-align: center;
  animation-name: fade-out;
  animation-duration: 2.0s;
  animation-fill-mode: forwards;
  animation-timing-function: ease-in;
}

.alert.game-complete {
  animation-delay: calc(var(--wordLength) * var(--tile-flip-speed) + .1s); 
}

.intro-modal-container {
    width: 100%;
    height: 100%;
    margin: 0 auto;
    position: absolute;
    top: 0;
    left: 0;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    z-index: 1100;
}


.intro-modal {
    display: block;
    position: relative;
    background-color: var(--color-tone-7);
    color: var(--color-tone-1);
    padding: 16px;
    font-size: .8rem;
    max-width: var(--max-game-width);
    align-content: center;
    align-items: center;
    text-align: left;
    margin: 0 auto;
    border: 1px solid var(--color-tone-6);
    border-radius: 5px;
}

.intro-modal .close-icon {
  user-select: none;
  cursor: pointer;
  width: 24px;
  height: 24px;
  position: absolute;
  top: 16px;
  right: 16px;
}
.intro-modal p{
  line-height: 1.1rem;
}

.intro-modal section.questions {
    font-size: .9rem;
}

.intro-modal section.questions h2 {
    font-size: .9rem;
    padding-top: 10px;
    border-top: 1px solid var(--color-tone-3);
}

.intro-modal section.questions h3 {
    font-size: .75rem;
}

.intro-modal section.questions p {
    font-size: .7rem;
    padding-left: 10px;
    color: var(--color-tone-2);
}

@keyframes fade-out {
  0%  {  opacity: 0.0; }
  1%  { opacity: 1.0; }
  90% {  opacity: 1.0; }
  100% { opacity: 0.0; }
}

@keyframes rejected-shake {
  0%   { transform: translate(-3px)}
  20%  { transform: translate(3px)}
  40%  { transform: translate(-3px)}
  60%  { transform: translate(3px)}
  80%  { transform: translate(-3px)}
  100% { transform: translate(3px)}
}

@keyframes tile-tbd {
  0%   {transform: scale(100%);}
  50%  {transform: scale(105%);}
  100% {transform: scale(100%);}
}

@keyframes flip-hor {
  0%   {transform: scale(1.0, 1.0);}
  50%  {transform: scale(1.0, 0.0);}
  100% {transform: scale(1.0, 1.0);}
}

@keyframes change-background {
  100%   {border: none; background-color: var(--tile-bg-color-name);}
}
@keyframes happy-tile {
  0% { transform: translate(0px, 0px); }
  50% { transform: translate(0px, -10px); }
  100% { transform: translate(0px, 0px); }
}